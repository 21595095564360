import { securedWrap } from '@mop/shared/utils/securedWrap';
import { loadScript } from '@mop/shared/utils/util';
const URL_EXCEPTIONS: string[] = [URLS.IMPRINT, URLS.PRIVACY];

let usercentricsPage = 0;
let userAlreadyConsented = false;

export default function useMopUsercentricsConsentClient() {
  const { $gtm, $mopConfig } = useNuxtApp();

  function isConsentRequired() {
    return !userAlreadyConsented;
  }

  async function isConsentServiceAllowed(service: string) {
    const details = await window.__ucCmp?.getConsentDetails();
    if (!details?.services) {
      return false;
    }
    const foundService: any = Object.values(details.services).find((s: any) => s.name === service);
    return foundService?.consent?.given === true;
  }

  async function isConsentCategoryAllowed(category: string) {
    const details = await window.__ucCmp?.getConsentDetails();
    if (!details?.categories) {
      return false;
    }
    return details.categories[category]?.state === 'ALL_ACCEPTED';
  }

  function isConsentRequiredOnCurrentPage(): boolean {
    return !URL_EXCEPTIONS.some((link) => useRoute().fullPath.includes(link));
  }

  async function isMarketingConsentAccepted() {
    return await isConsentCategoryAllowed('marketing');
  }

  async function isSaizConsentAccepted() {
    return await isConsentServiceAllowed('Saiz');
  }

  async function isEpoqConsentAccepted() {
    return await isConsentServiceAllowed('epoq');
  }

  // make sure to call only once
  function initScript() {
    if (useRoute().query.disablecookiebanner) {
      return;
    }

    fetch('https://web.cmp.usercentrics.eu/ui/loader.js', {
      mode: 'no-cors',
    })
      .then(() => {
        loadScript({
          source: 'https://web.cmp.usercentrics.eu/ui/loader.js',
          id: 'usercentrics-cmp',
          async: false,
          attributes: [
            {
              key: 'data-settings-id',
              value: $mopConfig.getUsercentricsId(),
            },
          ],
        });
      })
      .catch(initTracking);

    // According to https://usercentrics.com/docs/web/features/events/examples/first-visit/
    window.addEventListener('ucEvent', (e: any) => {
      const event = e.detail.event;
      const usercenrticsEventType = e.detail.type;
      const isConsentStatusEvent = event === 'consent_status';
      if (!isConsentStatusEvent) {
        return;
      }
      const isUsercentricsFirstUserVisit = usercenrticsEventType === 'IMPLICIT' && usercentricsPage === 0;
      if (isUsercentricsFirstUserVisit) {
        usercentricsPage = 1;
        return;
      }
      const isUsercentricsAlreadyConsented = usercenrticsEventType === 'EXPLICIT' && usercentricsPage === 0;
      if (isUsercentricsAlreadyConsented) {
        usercentricsPage = 1;
        userAlreadyConsented = true;
        initTracking();
        return;
      }
      const isUsercentricsChangedConsent = usercenrticsEventType === 'EXPLICIT' && usercentricsPage === 1;
      if (isUsercentricsChangedConsent) {
        if (userAlreadyConsented) {
          window.location.reload();
          return;
        }
        initTracking();
      }
    });
  }

  function initTracking() {
    // UC pushes at the same time as we do, and their updates needs to be first.
    setTimeout(() => {
      userAlreadyConsented = true;
      $gtm.initGtmScript();
      useMopSaizClient().initSaiz();
      useMopAffiliateTracking().initAffiliate();
    }, 300);
  }

  function openConsentBanner() {
    window.__ucCmp?.showSecondLayer();
  }

  return securedWrap({
    openConsentBanner,
    isConsentRequired,
    initScript,
    isConsentRequiredOnCurrentPage,
    isMarketingConsentAccepted,
    isSaizConsentAccepted,
    isEpoqConsentAccepted,
  });
}
