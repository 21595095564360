import { logError } from '@mop/shared/utils/logger';
import { isClient } from '@mop/shared/utils/util';
import { localStorageSet, localStorageRemove } from '@mop/shared/utils/localStorage';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { NuxtApp } from '#app';
import {
  createApiCms,
  createApiLocal,
  createApiCrmMiddleware,
  createApiBackbone,
  createApiAyc,
  createApiAws,
  createApiEpoq,
  createApiWallet,
} from '@/api';
import { countryConfigModel } from '@/models';
import type {
  CountryConfigResponseData,
  CountryAbTestConfigResponseData,
  CountryCategoriesConfigResponseData,
  CountryProductsConfigResponseData,
  CountryPopularityFlagConfigResponseData,
} from '@/types/countryConfig';
import { headersMiddleware } from '@/routerMiddleware/headers';

export default defineNuxtPlugin(async (nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt as NuxtApp;
  if (!app.$mopI18n) {
    return;
  }
  const isStoryblokLivePreview = app.$storyblokLivePreview.isEnabled;
  const { shopId } = app.$mopI18n;
  const config = useRuntimeConfig();
  const route = useRoute();
  const { cmsLanguage, cmsFallbackLanguage } = getCmsLanguages(app, route);

  nuxtApp.provide('apiLocal', createApiLocal());
  nuxtApp.provide(
    'apiCms',
    createApiCms({
      cmsApiKey: isStoryblokLivePreview
        ? config.public.STORYBLOK_API_KEY_PREVIEW
        : config.public.STORYBLOK_API_KEY_PUBLISHED,
      cmsVersion: isStoryblokLivePreview ? 'draft' : 'published',
      cmsLanguage,
      cmsFallbackLanguage,
      cmsRelease: String(route.query._storyblok_release),
    }),
  );

  nuxtApp.provide(
    'apiCrmMiddleware',
    createApiCrmMiddleware({
      url: config.public.CRM_URL,
      bapiShopId: shopId,
    }),
  );

  nuxtApp.provide(
    'apiBackbone',
    createApiBackbone({
      url: config.public.BAPI_URL,
      bapiShopId: shopId,
    }),
  );

  nuxtApp.provide(
    'apiAws',
    createApiAws({
      url: config.public.AWS_URL,
      bapiShopId: shopId,
    }),
  );

  const { cmsGlobalStoryListModelRef, initGlobalComponents } = useMopCmsGlobalComponents();
  // Only add independent stuff, for example searchRootCategories can't depend on country config settings

  const { searchRootCategories, rootCategoryListRef } = useMopRootCategories();
  await Promise.all([initGlobalComponents(), searchRootCategories()]);

  const maintenanceStory = cmsGlobalStoryListModelRef.value.getStoryModelByName(
    constants.STORYBLOK.GLOBAL_STORY_NAMES.MAINTENANCE,
  )!;
  const isDebugCookieEnabled = Boolean(app.$cookie.get(constants.COOKIE.DEBUG));
  const isMaintenance = maintenanceStory?.getAttribute('isActive') && !isDebugCookieEnabled;

  if (isMaintenance) {
    // According to: https://developers.google.com/search/blog/2011/01/how-to-deal-with-planned-site-downtime
    const event = useRequestEvent();
    setResponseStatus(event, 503);
  }

  nuxtApp.provide('maintenance', {
    isActive: isMaintenance,
    story: maintenanceStory,
  });

  nuxtApp.provide('rootCategories', rootCategoryListRef.value);

  nuxtApp.provide(
    'mopConfig',
    countryConfigModel(
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES)
        ?.getResponse() as unknown as CountryConfigResponseData,
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES_AB_TEST)
        ?.getResponse() as unknown as CountryAbTestConfigResponseData,
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES_CATEGORIES)
        ?.getResponse() as unknown as CountryCategoriesConfigResponseData,
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES_PRODUCTS)
        ?.getResponse() as unknown as CountryProductsConfigResponseData,
      cmsGlobalStoryListModelRef.value
        .getStoryModelByName(constants.STORYBLOK.GLOBAL_STORY_NAMES.SHOP_PREFERENCES_POPULARITY_FLAGS)
        ?.getResponse() as unknown as CountryPopularityFlagConfigResponseData,
    ),
  );

  nuxtApp.provide(
    'apiAyc',
    createApiAyc({
      url: config.public.AYC_URL,
      bapiShopId: shopId,
    }),
  );

  if (app.$mopConfig.getEpoqTenantId() && isClient) {
    nuxtApp.provide(
      'apiEpoq',
      createApiEpoq({
        url: config.public.EPOQ_URL.replace('{TENANT_ID}', app.$mopConfig.getEpoqTenantId()),
        tenantId: shopId,
      }),
    );
  }

  if (isClient) {
    nuxtApp.provide(
      'apiWallet',
      createApiWallet({
        url: config.public.WALLET_URL,
      }),
    );
  }

  addRouteMiddleware(async (to) => {
    if (!isClient) {
      headersMiddleware(to);
    }
    if (await redirectHandler(to)) {
      // stops furhter response processing, otherwise error 500 is very likely
      return false;
    }
  });

  if (isClient) {
    await initCustomer(app, route);
    useMopPromotions().initPromotions();
  }
});

async function initCustomer(app: NuxtApp, route: RouteLocationNormalizedLoaded) {
  const localePath = `/${app.$mopI18n.locale}`;
  // a case where customer is logging out from checkout
  if (route.path.includes(`/${URLS.LOGOUT}`)) {
    logoutCustomer(app, localePath);
    return;
  }
  // normal logout in app
  if (route.query.auth && window.self === window.top) {
    logoutCustomer(app, localePath);
    return;
  }
  const accessToken = getAccessToken(route.query.auth as string, app.$cookie.get(constants.COOKIE.ACCESS_TOKEN_LOCALE));
  if (!accessToken) {
    setAuthCookie(app, false);
    setAbTastyId();
    return;
  }

  app.$cookie.store(constants.COOKIE.ACCESS_TOKEN_LOCALE, accessToken, {
    path: localePath,
  });
  if (route.name === 'auth-redirect') {
    return;
  }
  const { customerModelRef, handleCustomer } = useMopCustomer();
  await handleCustomer(
    app.$mopI18n.shopId,
    getUuidAndSaveToCookie(app.$cookie, constants.COOKIE.BASKET),
    getUuidAndSaveToCookie(app.$cookie, constants.COOKIE.WISHLIST),
  );

  const isTokenInvalid: boolean = customerModelRef.value.isTokenInvalid();
  const isLoggedIn = !isTokenInvalid;

  setAuthCookie(app, isLoggedIn);
  setAbTastyId(isLoggedIn, customerModelRef.value.getReferenceKey());

  if (isLoggedIn) {
    const basketKey: string = customerModelRef.value.getBasketKey();
    const wishlistKey: string = customerModelRef.value.getWishlistKey();

    if (basketKey) {
      app.$cookie.store(constants.COOKIE.BASKET, basketKey);
    }

    if (wishlistKey) {
      app.$cookie.store(constants.COOKIE.WISHLIST, wishlistKey);
    }
  }

  if (isTokenInvalid) {
    app.$cookie.remove(constants.COOKIE.ACCESS_TOKEN_LOCALE, {
      path: localePath,
    });
  }
}

function setAbTastyId(isLoggedIn = false, customerReferenceKey = '') {
  if (isLoggedIn) {
    localStorageSet(constants.LOCAL_STORAGE.AB_TASTY, customerReferenceKey);
  } else {
    localStorageRemove(constants.LOCAL_STORAGE.AB_TASTY);
  }
}

function logoutCustomer(app: NuxtApp, localePath: string) {
  app.$cookie.remove(constants.COOKIE.ACCESS_TOKEN_LOCALE, {
    path: localePath,
  });
  app.$cookie.remove(constants.COOKIE.BASKET);
  app.$cookie.remove(constants.COOKIE.WISHLIST);
  setAuthCookie(app, false);
}

function setAuthCookie(app: NuxtApp, isLoggedIn = false) {
  app.$cookie.store(constants.COOKIE.IS_AUTHENTICATED, isLoggedIn ? 'true' : 'false');
}

function getAccessToken(authToken: string | string[], accessToken?: string) {
  try {
    accessToken = accessToken || undefined;
    authToken = Array.isArray(authToken) ? authToken[0] : authToken;
    if (authToken !== undefined) {
      return jwtDecode(authToken).accessToken || authToken;
    }
    return accessToken;
  } catch (error) {
    logError(error);
  }
}

function getCmsLanguages(nuxtApp: NuxtApp, route: RouteLocationNormalizedLoaded) {
  const STORYBLOK_DEFAULT_PAREMETER_LANGUAGE_KEY = 'default';
  try {
    let cmsLanguage: string = nuxtApp.$mopI18n.locale;
    let cmsFallbackLanguage: string = nuxtApp.$mopI18n.cmsFallbackLanguage || STORYBLOK_DEFAULT_PAREMETER_LANGUAGE_KEY;
    const storyblokLanguageParameter = route.query._storyblok_lang;
    if (storyblokLanguageParameter) {
      cmsLanguage = String(storyblokLanguageParameter);
      if (cmsLanguage === STORYBLOK_DEFAULT_PAREMETER_LANGUAGE_KEY) {
        cmsLanguage = defaultStoryblokLanguage;
        cmsFallbackLanguage = '';
      }
    }

    if (cmsLanguage === cmsFallbackLanguage) {
      cmsFallbackLanguage = '';
    }

    return {
      cmsLanguage,
      cmsFallbackLanguage,
    };
  } catch (error) {
    logError(error);
  }
  return {
    cmsLanguage: nuxtApp.$mopI18n.locale,
    cmsFallbackLanguage: STORYBLOK_DEFAULT_PAREMETER_LANGUAGE_KEY,
  };
}
