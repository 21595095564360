import { isClient } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { OverlayGroup, OverlayOverride, Overlay, UseOverlayOpenParams } from '@/types/overlay';

type OverlayListRef = Ref<Overlay[]>;
type ShowShadowRef = Ref<boolean>;
type BodyScrollLockRef = Ref<boolean>;
type ActiveOverlayRef = Ref<Overlay | null>;
type OverlayGroupListRef = { [key in OverlayGroup]?: Ref<Overlay[]> };

type OverlayComposableStorage = {
  overlayListRef: OverlayListRef;
  showShadowRef: ShowShadowRef;
  bodyScrollLockRef: BodyScrollLockRef;
  activeOverlayRef: ActiveOverlayRef;
  overlayGroupListRef: OverlayGroupListRef;
};

function generateComponentKey(params: UseOverlayOpenParams, group: OverlayGroup): string {
  const { type, componentName, props, overrideParams } = params;
  const value: object = {
    type,
    componentName,
    props,
    overrideParams,
    group,
  };
  if (overrideParams?.forceRender === true) {
    return JSON.stringify(value) + Date.now();
  }
  return JSON.stringify(value);
}

const defaultOverrideParams: OverlayOverride = {
  enableTransition: true,
  enableShadowClose: true,
  showClose: true,
  lockScroll: true,
  shadowColor: 'dark',
  persist: false,
  mouseLeaveTimeout: 200,
};

function useMopOverlay(group: OverlayGroup = 'global') {
  const storage = initStorage<OverlayComposableStorage>('useOverlay');

  const overlayListRef: OverlayListRef = storage.get('overlayListRef') ?? storage.saveAndGet('overlayListRef', ref([]));

  const showShadowRef: ShowShadowRef = storage.get('showShadowRef') ?? storage.saveAndGet('showShadowRef', ref(false));

  const bodyScrollLockRef: BodyScrollLockRef =
    storage.get('bodyScrollLockRef') ?? storage.saveAndGet('bodyScrollLockRef', ref(false));

  const activeOverlayRef: ActiveOverlayRef =
    storage.get('activeOverlayRef') ?? storage.saveAndGet('activeOverlayRef', ref(null));

  const overlayGroupListRef: OverlayGroupListRef =
    storage.get('overlayGroupListRef') ?? storage.saveAndGet('overlayGroupListRef', { [group]: ref([]) });

  overlayGroupListRef[group] = computed(() =>
    overlayListRef.value.filter((overlay: Overlay) => {
      return overlay.group === group;
    }),
  );

  async function init(params: UseOverlayOpenParams, overrideGroup?: OverlayGroup) {
    const { type, componentName, urlHash, overrideParams, props, onClose } = params;
    const targetGroup: OverlayGroup = overrideGroup || group;

    await setActiveOverlay({
      componentName,
      urlHash,
      type,
      group: targetGroup,
      props,
      override: {
        ...defaultOverrideParams,
        ...overrideParams,
      },
      componentKey: params.componentKey || generateComponentKey(params, targetGroup),
      onClose,
      isOpen: false,
    });
  }

  async function open(params: UseOverlayOpenParams, overrideGroup?: OverlayGroup): Promise<void> {
    const { type, componentName, urlHash, overrideParams, props, onClose, onOpen } = params;
    const targetGroup: OverlayGroup = overrideGroup || group;

    await setActiveOverlay({
      componentName,
      urlHash,
      type,
      group: targetGroup,
      props,
      override: {
        ...defaultOverrideParams,
        ...overrideParams,
      },
      componentKey: params.componentKey || generateComponentKey(params, targetGroup),
      onClose,
      onOpen,
    });

    openActiveOverlay();
    updateScrollBlocking();
    toggleKeyUpHandling(true);
  }

  async function setActiveOverlay(activeOverlay: Overlay): Promise<void> {
    const isAnotherOverlayOpen =
      activeOverlayRef.value?.isOpen && activeOverlayRef.value.componentKey !== activeOverlay.componentKey;
    const activeOverlayFromList = overlayListRef.value.find((overlay: Overlay) => {
      return overlay.componentKey === activeOverlay.componentKey;
    });
    if (isAnotherOverlayOpen) {
      await waitForClose();
    }
    if (!activeOverlayFromList) {
      overlayListRef.value.push(activeOverlay);
    }
    activeOverlayRef.value = activeOverlayFromList || activeOverlay;
  }

  function openActiveOverlay(): void {
    if (activeOverlayRef.value) {
      activeOverlayRef.value.isOpen = true;
    }
    showShadowRef.value = true;
    onOpen();
  }

  function updateScrollBlocking(): void {
    bodyScrollLockRef.value =
      typeof activeOverlayRef.value?.override?.lockScroll === 'string'
        ? (activeOverlayRef.value?.override.lockScroll as String).length > 0
        : activeOverlayRef.value?.override.lockScroll === true;
  }

  function toggleKeyUpHandling(value: boolean): void {
    if (!isClient) {
      return;
    }

    if (value) {
      document.addEventListener('keyup', keyUpHandler);
    } else {
      document.removeEventListener('keyup', keyUpHandler);
    }
  }

  function keyUpHandler(event: KeyboardEvent): void {
    const isTargetAnInput: boolean =
      event.target !== undefined && 'INPUT SELECT checkbox radio'.includes((event.target as HTMLElement).tagName);

    if (event.keyCode === constants.KEY_CODES.ESCAPE && isTargetAnInput) {
      (event.target as HTMLElement).blur();
      return;
    }
    if (event.keyCode === constants.KEY_CODES.ESCAPE && canClose()) {
      closeAll();
    }
  }

  function waitForClose(): Promise<void> {
    const router = useRouter();
    return new Promise((resolve) => {
      if (!activeOverlayRef.value?.isOpen) {
        return resolve();
      }
      const route = router.currentRoute.value;
      const onClose: Function | undefined = activeOverlayRef.value.onClose;
      activeOverlayRef.value.isOpen = false;
      if (activeOverlayRef.value.urlHash && route.hash?.substring(1) === activeOverlayRef.value.urlHash) {
        router.push({ hash: '', query: route.query });
      }
      onClose && onClose();

      // remove from cache in case of overlay needs full re-render next time
      // timeout used to finish closing css animation
      if (activeOverlayRef.value.override.forceRender) {
        setTimeout(() => {
          if (activeOverlayRef.value) {
            removeOverlay(activeOverlayRef.value.componentName);
          }
          return resolve();
        }, 150);
      } else {
        return resolve();
      }
    });
  }

  function onOpen() {
    const onOpenCallback: Function | undefined = activeOverlayRef.value?.onOpen;

    onOpenCallback && onOpenCallback();
  }

  function canClose() {
    if (!activeOverlayRef.value) {
      return false;
    }

    return activeOverlayRef.value?.override?.enableShadowClose === true;
  }

  function canCloseOnRouteChange(routeName: string) {
    if (activeOverlayRef?.value?.override?.persist) {
      return false;
    }

    return !activeOverlayRef?.value?.override?.persistRoutes?.includes(routeName);
  }

  async function closeAll() {
    showShadowRef.value = false;
    bodyScrollLockRef.value = false;
    await waitForClose();
    toggleKeyUpHandling(false);
  }

  async function closeActive(callbackValue: string) {
    const onCloseCallback: Function | undefined = activeOverlayRef.value?.onClose;
    if (onCloseCallback) {
      await onCloseCallback(callbackValue);
      // Make sure to not fire on close callback twice
      activeOverlayRef.value!.onClose = undefined;
    }
    await closeAll();
  }

  function removeOverlay(componentName: string) {
    const index: number = overlayListRef.value.findIndex((overlay: Overlay) => {
      return overlay.componentName === componentName;
    });
    if (index !== -1) {
      overlayListRef.value.splice(index, 1);
    }
    activeOverlayRef.value = null;
    toggleKeyUpHandling(false);
  }

  function openFromUrlHash(hash: string): boolean {
    switch (hash) {
      case OVERLAYS.COOKIE.URL_HASH:
        useMopUsercentricsConsentClient().openConsentBanner();
        break;
      case OVERLAYS.COUNTRY.URL_HASH:
        useMopCountrySelector().openCountrySelector();
        break;
      case OVERLAYS.SUPER_BANNER.URL_HASH:
        useMopSuperBannerClient().openSuperBannerOverlay();
        break;
      default:
        return false;
    }

    return true;
  }

  return securedWrap({
    init,
    open,
    closeAll,
    closeActive,
    canClose,
    canCloseOnRouteChange,
    removeOverlay,
    overlayListRef,
    overlayGroupListRef: overlayGroupListRef[group],
    showShadowRef,
    bodyScrollLockRef,
    activeOverlayRef,
    openFromUrlHash,
  });
}
export default useMopOverlay;
