import { securedWrap } from '@mop/shared/utils/securedWrap';
import { isClient } from '@mop/shared/utils/util';
import cookie from '@mop/shared/utils/cookie';
import type { LocationQuery } from 'vue-router';

const SEARCH_ENGINES: string[] = ['google', 'bing', 'yahoo'];

const MEDIUM: { [key: string]: string } = {
  DIRECT: 'direct',
  ORGANIC: 'organic',
};

export function useMopAffiliateTracking() {
  const route = useRoute();
  const cookieStorage = cookie();
  const { isMarketingConsentAccepted } = useMopUsercentricsConsentClient();
  async function initAffiliate() {
    if (!isClient || !(await isMarketingConsentAccepted())) {
      return;
    }
    const newSource = getSource(route.query);
    const newMedium = getMedium(route.query);
    const newCookieValue = `${newSource}|${newMedium}`;
    const cookieValue: string = await getAffiliateTrackingValue();
    const newValueIsAllowedToOverwrite = newMedium && ![MEDIUM.DIRECT, MEDIUM.ORGANIC].includes(newMedium);
    if (cookieValue && !newValueIsAllowedToOverwrite) {
      return;
    }
    const expirationDate: Date = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);
    cookieStorage.store(constants.COOKIE.AFFILIATE_TRACKING, newCookieValue, {
      expires: expirationDate,
    });
  }

  function deleteAffiliate() {
    if (!isClient) {
      return;
    }
    cookieStorage.remove(constants.COOKIE.AFFILIATE_TRACKING);
  }

  async function getAffiliateTrackingValue() {
    if (!isClient || !(await isMarketingConsentAccepted())) {
      return '';
    }
    return cookieStorage.get(constants.COOKIE.AFFILIATE_TRACKING);
  }

  function getSource(query: LocationQuery) {
    if ((query.gclid || query.dclid) && !query.utm_source) {
      return getRefererHostname().split('.')[0];
    }
    if (query.utm_source) {
      return Array.isArray(query.utm_source) ? query.utm_source[0] : query.utm_source;
    }
    return getRefererSource();
  }

  function getMedium(query: LocationQuery) {
    if (query.gclid && !query.utm_medium) {
      return 'cpc';
    }
    if (query.dclid) {
      return 'ban';
    }
    if (query.utm_medium) {
      return Array.isArray(query.utm_medium) ? query.utm_medium[0] : query.utm_medium;
    }
    return getRefererMedium();
  }

  function getRefererHostname() {
    const url: HTMLAnchorElement = document.createElement('a');
    url.href = document.referrer;
    return url.hostname.replace('www.', '');
  }

  function getRefererSource() {
    return getRefererMedium() === MEDIUM.DIRECT ? MEDIUM.DIRECT : getRefererHostname();
  }

  function getRefererMedium() {
    const referer: string = document.referrer;
    if (!referer) {
      return MEDIUM.DIRECT;
    }
    return SEARCH_ENGINES.some((engine) => engine.includes(referer)) ? MEDIUM.ORGANIC : MEDIUM.DIRECT;
  }

  return securedWrap({
    initAffiliate,
    getAffiliateTrackingValue,
    deleteAffiliate,
  });
}
