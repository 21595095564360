import type {
  CountryConfig,
  PopularityFlagTypes,
  CountryConfigResponseData,
  CountryAbTestConfigResponseData,
  CountryCategoriesConfigResponseData,
  CountryProductsConfigResponseData,
  CountryPopularityFlagConfigResponseData,
  CountryPopularityFlagConfigValues,
  CountryShipping,
  CountryAbTestData,
  CountryAbTestDataGroup,
  CountryAbTestConfig,
} from '@/types/countryConfig';

import type { ProductDataPopularityFlag, ProductDataPopularityFlagType } from '@/types/product';

export function countryConfigModel(
  responseData: CountryConfigResponseData | null,
  responseDataAbTest: CountryAbTestConfigResponseData | null,
  responseDataCategories: CountryCategoriesConfigResponseData | null,
  responseDataProducts: CountryProductsConfigResponseData | null,
  responseDataPopularityFlags: CountryPopularityFlagConfigResponseData | null,
) {
  const response = responseData ?? {};
  const responseAbTest = responseDataAbTest ?? {};
  const responseCategories = responseDataCategories ?? {};
  const responseProducts = responseDataProducts ?? {};
  const responsePopularityFlags = responseDataPopularityFlags ?? {};
  const categoryConfig = responseCategories.data?.content?.categoryPreferences || {};
  const productConfig = responseProducts.data?.content?.productPreferences || {};
  const popularityFlagConfig = responsePopularityFlags.data?.content;

  return {
    hasError(): boolean {
      return response.error !== undefined || !responseData?.data?.content;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    showLowestPriorPrice(): boolean {
      return response.data?.content?.showLowestPriorPrice === true;
    },

    isSaleEnabled(): boolean {
      return response.data?.content?.enableSalePrice === true;
    },

    isSavingLabelEnabled(): boolean {
      return response.data?.content?.enableSalePriceSavingLabel === true;
    },

    isClickAndCollectEnabled(): boolean {
      return response.data?.content?.enableClickAndCollect === true;
    },

    isLoyaltyProgramEnabled(): boolean {
      return response.data?.content?.enableLoyaltyProgram === true;
    },

    isGuestBuyerMarketingEnabled(): boolean {
      return response.data?.content?.enableGuestBuyerMarketing === true;
    },

    isMemberryInfoboxEnabled(): boolean {
      return response.data?.content?.enableMemberryInfobox === true;
    },

    isReserveAndCollectEnabled(): boolean {
      return response.data?.content?.enableReserveAndCollect === true;
    },

    showMembersNonBonusMigrationOverlay(): boolean {
      return response.data?.content?.showMembersNonBonusMigrationOverlay === true;
    },

    getPriceFilterIncrement(): number {
      return parseInt(response.data?.content?.priceFilterIncrement ?? '5');
    },

    getMinOrderAmount(): number {
      return parseFloat(response.data?.content?.minOrderAmount ?? '29.9');
    },

    getMinOrderAmountText(): string {
      return response.data?.content?.minOrderAmountText ?? '';
    },

    getShippingInfo(): CountryShipping {
      const data: CountryConfig | undefined = response.data?.content;

      return {
        cost: parseFloat(data?.shippingCost ?? '8.95'),
        expressDeliveryTime: data?.expressDeliveryTime ?? '1-2',
        standardDeliveryTime: data?.standardDeliveryTime ?? '2-3',
        backOrderDeliveryTime: data?.backOrderDeliveryTime ?? '7',
        shippingMethod: data?.shippingMethod ?? 'dhl',
      };
    },

    getShippingCostText(): string {
      return response.data?.content?.shippingCostText ?? '';
    },

    getFreeShippingMinAmount(): number {
      return Number(response.data?.content.freeShippingMinAmount) ?? 0;
    },

    getVatLegalText(): string {
      return response.data?.content?.vatLegalText ?? '';
    },

    getCartNotification(): string {
      return response.data?.content?.cartNotification ?? '';
    },

    getCartTeaserText(): string {
      return response.data?.content?.cartTeaserText ?? '';
    },

    getSevenSendersWidgetId(): string {
      return response.data?.content?.sevenSendersWidgetId ?? '78ec02dd-1081-4531-8eb3-ce60753c9a89';
    },

    getAvailablePaymentMethods(): string[] {
      return response.data?.content?.availablePaymentMethods ?? [];
    },

    getAvailableShippingMethods(): string[] {
      return response.data?.content?.availableShippingMethods ?? [];
    },

    getSupportEmail(): string {
      return response.data?.content.supportEmail ?? '';
    },

    getMaxProductsPerPage(): number {
      return response.data?.content.maxProductsPerPage ?? 48;
    },

    isChatEnabled(): boolean {
      return response.data?.content?.enableChat === true;
    },

    getChatOrgId(): string {
      return response.data?.content.chatOrgId ?? '';
    },

    getChatDepartmentId(): number {
      return Number(response.data?.content.chatDepartmentId ?? 0);
    },

    isGlobalEEnabled(): boolean {
      return response.data?.content?.enableGlobalE === true;
    },

    isCheeringMessagesEnabled(): boolean {
      return response.data?.content?.enableCheeringMessages === true;
    },

    isAbtastyEnabled(): boolean {
      return response.data?.content?.enableAbtasty === true;
    },

    getAbtastyId(): string {
      return response.data?.content?.abtastyId ?? '';
    },

    getEpoqTenantId(): string {
      return response.data?.content?.epoqTenantId ?? '';
    },

    loadAbtastyAsync(): boolean {
      return response.data?.content?.loadAbtastyAsync === true;
    },

    getGtmDelay(): number {
      return Math.max(0, response.data?.content?.gtmDelay ?? 0);
    },

    isAbTestCookieEnabled(): boolean {
      return responseAbTest.data?.content?.enableAbTestCookie === true;
    },

    getAbTestCookieData(): CountryAbTestData | undefined {
      const content: CountryAbTestConfig | undefined = responseAbTest.data?.content;
      if (!content || !this.isAbTestCookieEnabled) {
        return;
      }
      const shareOfTrafficPercentage = parseInt(content.shareOfAbTestTrafficPercentage) || 0;
      if (!shareOfTrafficPercentage) {
        return;
      }

      function getValidGroupValues(cookieValue: string, cookiePercentage: string): CountryAbTestDataGroup {
        const value = cookieValue.trim() || '';
        const percentage = cookiePercentage ? parseInt(cookiePercentage) || 0 : 0;
        if (value && percentage) {
          return { value, percentage };
        }
        return { value: '', percentage: 0 };
      }

      const controlGroup = getValidGroupValues(content.controlGroupCookieValue, content.controlGroupPercentage);
      const testGroup1 = getValidGroupValues(content.testGroup1CookieValue, content.testGroup1CookiePercentage);
      const testGroup2 = getValidGroupValues(content.testGroup2CookieValue, content.testGroup2CookiePercentage);

      const groups = [controlGroup, testGroup1, testGroup2].filter((group) => group.value);

      let cookiePercentagesSum = 0;
      groups.forEach((group) => (cookiePercentagesSum += group.percentage));

      const isAbTestCookieDataValid = cookiePercentagesSum === 100;
      if (!isAbTestCookieDataValid) {
        return;
      }

      return {
        shareOfTrafficPercentage,
        groups,
      };
    },

    getCategoryPreference(mopId: string) {
      return categoryConfig?.[mopId] ?? {};
    },

    // id = master_color or master
    getProductPreference(id: string) {
      const masterId: string = id?.split('_')?.[0];
      return productConfig[id] ?? (id !== masterId ? productConfig[masterId] : null) ?? null;
    },

    // id = master_color
    getPopularityFlagPreference(id: string, type: PopularityFlagTypes): ProductDataPopularityFlag | undefined {
      const popularityFlag = (popularityFlagConfig?.[type] as CountryPopularityFlagConfigValues)?.[id];
      if (popularityFlag) {
        return {
          type: type.replace('items', '').toLowerCase() as ProductDataPopularityFlagType,
          count: popularityFlag,
        };
      }
    },

    isPopularityFlagEnabledForSaleProducts() {
      return popularityFlagConfig?.showOnSaleProducts === true;
    },

    isSeoFilterUrlsEnabled(): boolean {
      return response.data?.content?.enableSeoFilterUrls === true;
    },

    isGiftCardMessageEnabled(): boolean {
      return response.data?.content?.enableGiftCardMessage === true;
    },

    isReturnPortalEnabled(): boolean {
      return response.data?.content?.enableReturnPortal === true;
    },

    isSizeAdviceEnabled(): boolean {
      return response.data?.content?.enableSizeAdvice === true;
    },

    isWalletIntegrationEnabled(): boolean {
      return this.isLoyaltyProgramEnabled() && response.data?.content?.enableWalletIntegration === true;
    },

    getUsercentricsId(): string {
      return response.data?.content?.usercentricsId ?? '';
    },

    getDebugData(): any {
      return response;
    },
  };
}
