<script setup lang="ts">
import type { ObserveScrollConfig, ActionPosition } from '@mop/types';
import { getCta, getElementUiObject, getImage, getLink, getMedia } from '@mop/cms/utils/utils';
import { scrollToPosition, isClient } from '@mop/shared/utils/util';
import type { CmsUiCardItem } from '@mop/cms/types';
import type { CmsContentElementModel } from '@/types/cms';
import { cmsContentElementListModel } from '@/models/cms/cmsContentElementModel';

defineOptions({
  name: 'MopCmsTabNavigation',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const route = useRoute();
const router = useRouter();
const data = props.data;
const showTabImages = data.showTabImages;
const tabContentBasedOn = data.firstTabContent as 'tab' | 'preview' | 'override'; // tab - content from all tabs, preview - preview content from tabs, override - use bodyoverride
const hoverTabGroupRef = ref<HTMLElement>();
const tabBodyEl = ref<HTMLElement>();
const tabNavigationEl = ref<HTMLElement>();
const selectedTabRef = ref(0);
const element = getElementUiObject(data, {});
const tabItems = [
  {
    label: data.headline,
    image: getImage(data.image),
    element,
  },
];
let timerFadeIn: any;
let timerFadeOut: any;
const firstLoadRef = ref(true);
const loadingRef = ref(true);
const isNavStickyRef = ref(false);
const hasAdditionalSidePaddingRef = ref(false);
const minHeightRef = ref('auto');
const previewContentList: CmsUiCardItem[] = [];
const contentListRef = ref<CmsContentElementModel[][]>([]);

const tabContentList: CmsContentElementModel[][] = [];

data.body?.forEach((tabNavigationItem: any, index: number) => {
  tabItems.push({
    label: tabNavigationItem.headline,
    image: getImage(tabNavigationItem.image),
    element: getElementUiObject(tabNavigationItem, {}),
  });

  tabContentList.push(
    cmsContentElementListModel({
      data: tabNavigationItem.body,
    }).getCmsContentElementModelList(),
  );

  if (tabContentBasedOn === 'preview') {
    previewContentList.push(
      getElementUiObject(tabNavigationItem, {
        headline: {
          text: tabNavigationItem.previewHeadline,
          isUppercase: tabNavigationItem.previewHeadlineUppercase === true,
        },
        subline: tabNavigationItem.previewSubHeadline,
        cta: getCta({
          text: tabNavigationItem.previewCtaText,
        }),
        link: getLink({
          url: `${route.path}${index >= 0 ? `?tab=${index + 1}` : ''}`,
        }),
        textColor: tabNavigationItem.previewTextColor?.color ?? '',
        media: getMedia({
          ...tabNavigationItem,
          image: tabNavigationItem.previewImage,
        }),
      } as CmsUiCardItem),
    );
  }
});

watch(
  selectedTabRef,
  (index) => {
    if (!isClient) {
      return;
    }
    loadingRef.value = true;
    clearTimeout(timerFadeIn);
    clearTimeout(timerFadeOut);

    const query = { ...route.query };
    delete query.tab;
    const newTabValue = index === 0 ? undefined : index;
    router.push({
      path: route.fullPath,
      query: { tab: newTabValue, ...query },
    });

    timerFadeOut = setTimeout(() => {
      hasAdditionalSidePaddingRef.value = false;
      minHeightRef.value = tabBodyEl.value!.offsetHeight + 'px';
      if (index === 0) {
        if (tabContentBasedOn === 'tab') {
          contentListRef.value = tabContentList;
        } else if (tabContentBasedOn === 'override') {
          contentListRef.value = [
            cmsContentElementListModel({
              data: data.bodyOverride,
            }).getCmsContentElementModelList(),
          ];
        } else {
          contentListRef.value = [];
          hasAdditionalSidePaddingRef.value = true;
        }
      } else {
        contentListRef.value = [tabContentList[index - 1]];
      }
      const contentElements: CmsContentElementModel[] = [];
      contentListRef.value.forEach((content) => {
        contentElements.push(...content);
      });
      useMopPromotionProducts().initPromotionProducts(contentElements);
    }, 300);

    timerFadeIn = setTimeout(() => {
      loadingRef.value = false;

      if (firstLoadRef.value) {
        firstLoadRef.value = false;
        return;
      }

      const element = tabNavigationEl.value!;
      const tabGroupEl = element.getElementsByClassName('ui-tabs__list-wrap')[0] as HTMLElement;
      const stickyStartPosition = getStickyStartPosition(element);
      scrollToPosition(stickyStartPosition - tabGroupEl.offsetHeight);
      minHeightRef.value = 'auto';
    }, 1000);
  },
  { immediate: true },
);

watch(
  () => route.query.tab,
  (tab) => {
    let tabIndex = parseInt(String(tab) || '0');
    if (isNaN(tabIndex)) {
      tabIndex = 0;
    }
    selectedTabRef.value = Math.max(0, tabIndex);
  },
  { immediate: true },
);

function getStickyStartPosition(element: HTMLElement) {
  const tabGroupEl = element.getElementsByClassName('ui-tabs__list-wrap')[0] as HTMLElement;
  return (
    element.getBoundingClientRect().top +
    tabGroupEl.offsetHeight -
    (hoverTabGroupRef.value?.offsetHeight ?? 0) +
    window.scrollY
  );
}

const stickyConfig: ObserveScrollConfig = {
  calculateActionPositions: (element) => {
    if (!element) {
      return [];
    }
    const stickyStartPosition = getStickyStartPosition(element);

    const actionPositionSticky: ActionPosition = {
      onEnter() {
        isNavStickyRef.value = true;
      },
      onLeave() {
        isNavStickyRef.value = false;
      },
      positionStart: stickyStartPosition,
    };

    return [actionPositionSticky];
  },
};
</script>

<template>
  <div
    ref="tabNavigationEl"
    v-observe-scroll="stickyConfig"
    :style="{
      '--minHeight': minHeightRef,
    }"
    :class="[
      'tab-navigation',
      {
        'tab-navigation--sticky': isNavStickyRef,
        'tab-navigation--loading': loadingRef,
        'tab-navigation--additional-padding': hasAdditionalSidePaddingRef,
      },
    ]"
  >
    <div class="tab-navigation__tabs">
      <Ui2TabGroup v-model="selectedTabRef" divider :align="data.align">
        <template #tabs>
          <template v-if="showTabImages">
            <Ui2TabImage
              v-for="tabItem in tabItems"
              :key="`tab-${tabItem.label}`"
              v-storyblok-editable="tabItem.element"
            >
              <template #image><UiImage :image="tabItem.image" cover class="tab-navigation__image" /></template>
              <template #label>{{ tabItem.label }}</template>
            </Ui2TabImage>
          </template>
          <template v-else>
            <Ui2Tab v-for="tabItem in tabItems" :key="`tab-${tabItem.label}`" v-storyblok-editable="tabItem.element">
              {{ tabItem.label }}
            </Ui2Tab>
          </template>
        </template>
      </Ui2TabGroup>
    </div>

    <ClientOnly>
      <div ref="hoverTabGroupRef" class="tab-navigation__tabs-hover">
        <Ui2TabGroup v-model="selectedTabRef" divider :align="data.align">
          <template #tabs>
            <Ui2Tab
              v-for="tabItem in tabItems"
              :key="`tab-duplicate-${tabItem.label}`"
              v-storyblok-editable="tabItem.element"
            >
              {{ tabItem.label }}
            </Ui2Tab>
          </template>
        </Ui2TabGroup>
      </div>

      <div class="tab-navigation__loading">
        <Ui2Loader size="lg" />
      </div>
      <div ref="tabBodyEl" class="tab-navigation__body">
        <div v-if="contentListRef.length">
          <MopCmsContentElementsSlot
            v-for="(content, index) in contentListRef"
            :id="`tab-content-${index}-${selectedTabRef}`"
            :key="`tab-content-${index}-${selectedTabRef}`"
            :elements="content"
          />
        </div>
        <div v-else class="tab-navigation__preview">
          <UiCmsCardItem
            v-for="(cardData, index) in previewContentList"
            :key="`tab-preview-${index}`"
            :data="cardData"
          />
        </div>
      </div>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
.tab-navigation__tabs {
  margin: 0 $space-12 $space-40;

  @include v2-apply-upto(mobile) {
    margin: 0 $space-12 $space-24;
  }
}

.tab-navigation__tabs-hover {
  @include v2-z(layers, superbanner);

  position: fixed;
  top: 0;
  width: 100%;
  padding: $space-16 $space-64;
  background: $color-core-white;
  transform: translateY(-100%);
  transition: transform 0.3s;

  @include v2-apply-upto(mobile) {
    padding: 0;
  }
}

.tab-navigation--sticky {
  .tab-navigation__tabs-hover {
    transform: translateY(0);
  }
}

.tab-navigation__image {
  width: 96px;
  height: 96px;
}

.tab-navigation__preview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include v2-apply-upto(mobile) {
    grid-template-columns: 1fr;
  }
}

.tab-navigation__loading {
  position: absolute;
  left: 50%;
  opacity: 0;
  transition: opacity ease 0.2s;
}

.tab-navigation__body {
  opacity: 1;
  transition: opacity ease 0.1s;
}

.tab-navigation--loading {
  .tab-navigation__loading {
    opacity: 1;
  }

  .tab-navigation__body {
    min-height: var(--minHeight);
    opacity: 0;
  }
}

.tab-navigation--additional-padding {
  .tab-navigation__body {
    padding: 0 $space-16;
  }
}
</style>
